import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";
import { SocialLinks } from "social-links";
export default class extends Controller {

  static get targets() {
    return [
      "firstNameInput",
      "lastNameInput",
      "emailInput",
      "phoneInput",
      "professionSlugSelect",
      "socialNetworkIdSelect",
      "socialNetworkUrlInput",
      "socialNetworkFollowersSelect",
      "privacyAcceptanceCheckBox",
      "backwardFormButton",
      "submitFormButton",
      "loadingFormButton"
    ];
  }

  static values = {
    validFirstName:                 { type: Boolean, default: false },
    validLastName:                  { type: Boolean, default: false },
    validEmail:                     { type: Boolean, default: false },
    validPhone:                     { type: Boolean, default: false },
    validProfessionSlug:            { type: Boolean, default: false },
    validSocialNetworkId:           { type: Boolean, default: false },
    validSocialNetworkUrl:          { type: Boolean, default: false },
    validSocialNetworkFollowers:    { type: Boolean, default: false },
    requiredSocialNetworkData:      { type: Boolean, default: false },
    validPrivacyAcceptanceCheckBox: { type: Boolean, default: false }
  }

  connect() {
    const phoneInputSelector = document.querySelector("#user_metadata_phone");

    if (phoneInputSelector !== null) {

      this.phoneInputObject = intlTelInput(phoneInputSelector, {
        initialCountry: I18n.locale,
        hiddenInput: "phone_full",
        formatOnInit: true,
        separateDialCode: true,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js"
      });
    }

    this.socialLinksObject = new SocialLinks();
  }

  typingFirstName(evt) {
    const firstNameValue = this.firstNameInputTarget.value;
    const firstNameMinLength = 2;

    if (firstNameValue.length <= firstNameMinLength) {

      this.validFirstNameValue = false;
      this.firstNameInputTarget.classList.remove("is-valid");
    } else {

      this.validFirstNameValue = true;
      this.firstNameInputTarget.classList.remove("is-invalid");
      this.firstNameInputTarget.classList.add("is-valid");
    }

  }

  typingLastName(evt) {
    const lastNameValue = this.lastNameInputTarget.value;
    const lastNameMinLength = 2;

    if (lastNameValue.length <= lastNameMinLength) {

      this.validLastNameValue = false;
      this.lastNameInputTarget.classList.remove("is-valid");
    } else {

      this.validLastNameValue = true;
      this.lastNameInputTarget.classList.remove("is-invalid");
      this.lastNameInputTarget.classList.add("is-valid");
    }
  }

  typingEmail(evt) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValue = this.emailInputTarget.value;

    if (regex.test(String(emailValue).toLowerCase())) {

      this.validEmailValue = true;
      this.emailInputTarget.classList.remove("is-invalid");
      this.emailInputTarget.classList.add("is-valid");

    } else {

      this.validEmailValue = false;
      this.emailInputTarget.classList.remove("is-valid");
    }
  }

  typingPhone(evt) {
    const phoneValueSelector  = this.phoneInputTarget;
    const phonePrefixSelector = document.querySelector("input[name='user_metadata[phone_prefix]']");
    const phoneFullSelector   = document.querySelector("input[name='user_metadata[phone_full]']");
    const phoneLabelSelector  = document.querySelector("div#user_metadata_phone");

    if (this.phoneInputObject.isValidNumber()) {

      this.validPhoneValue = true;
      this.phoneInputTarget.classList.remove("is-invalid");
      this.phoneInputTarget.classList.add("is-valid");
      phoneLabelSelector.classList.remove("d-block");
      phoneValueSelector.value  = this.phoneInputObject.getNumber(2);
      phoneFullSelector.value   = this.phoneInputObject.getNumber();
      phonePrefixSelector.value = `+${this.phoneInputObject.getSelectedCountryData()["dialCode"]}`;

    } else {

      this.validPhoneValue = false;
      this.phoneInputTarget.classList.remove("is-valid");
      phoneFullSelector.value   = "";
      phonePrefixSelector.value = "";
    }
  }

  selectProfessionSlug(evt) {
    const professionSlugValue = this.professionSlugSelectTarget.value;

    if (professionSlugValue != "") {

      this.validProfessionSlugValue = true;
      this.professionSlugSelectTarget.classList.remove("is-invalid");
      this.professionSlugSelectTarget.classList.add("is-valid");

    } else {

      this.validProfessionSlugValue = false;
      this.professionSlugSelectTarget.classList.remove("is-valid");
    }
  }

  selectSocialNetworkId(evt) {
    const socialNetworkIdValue         = this.socialNetworkIdSelectTarget.value;
    const socialNetworkUrlTarget       = this.socialNetworkUrlInputTarget;
    const socialNetworkSlugSelector    = document.querySelector("input[name='user_metadata[social_network_slug]']");
    const socialNetworkFollowersTarget = this.socialNetworkFollowersSelectTarget;

    if (socialNetworkIdValue != "") {
      this.validSocialNetworkIdValue = true;
      this.socialNetworkIdSelectTarget.classList.remove("is-invalid");
      this.socialNetworkIdSelectTarget.classList.add("is-valid");

      if (socialNetworkIdValue != "no_say") {

        this.requiredSocialNetworkDataValue = true;
        socialNetworkUrlTarget.parentElement.classList.remove("d-none");
        socialNetworkFollowersTarget.parentElement.classList.remove("d-none");
        
        if (this.validateSocialNetworkUrl()) {

          this.socialNetworkUrlInputTarget.classList.remove("is-invalid");
          this.socialNetworkUrlInputTarget.classList.add("is-valid");
        } else {

          if (this.socialNetworkUrlInputTarget.value.length != 0) {
            this.socialNetworkUrlInputTarget.classList.add("is-invalid");
            this.socialNetworkUrlInputTarget.classList.remove("is-valid");
          }
        }

      } else {

        this.requiredSocialNetworkDataValue = false;

        socialNetworkUrlTarget.parentElement.classList.add("d-none");
        socialNetworkUrlTarget.classList.remove("is-valid");
        socialNetworkUrlTarget.value    = ""
        socialNetworkSlugSelector.value = "";

        socialNetworkFollowersTarget.parentElement.classList.add("d-none");
        socialNetworkFollowersTarget.classList.remove("is-valid");
        socialNetworkFollowersTarget.value = "no_say";
      }

    } else {

      this.validSocialNetworkIdValue = false;
      this.socialNetworkIdSelectTarget.classList.remove("is-valid");
      this.requiredSocialNetworkDataValue = false;

      socialNetworkUrlTarget.parentElement.classList.add("d-none");
      socialNetworkUrlTarget.classList.remove("is-valid");
      socialNetworkUrlTarget.value    = "";
      socialNetworkSlugSelector.value = "";

      socialNetworkFollowersTarget.parentElement.classList.add("d-none");
      socialNetworkFollowersTarget.classList.remove("is-valid");
      socialNetworkFollowersTarget.value = "";
    }
  }

  typingSocialNetworkUrl(evt) {
    const socialNetworkIdValue      = this.socialNetworkIdSelectTarget.value;
    const socialNetworkUrlValue     = this.socialNetworkUrlInputTarget.value;
    const socialNetworkSlugSelector = document.querySelector("input[name='user_metadata[social_network_slug]']");

    if (this.validateSocialNetworkUrl()) {

      this.validSocialNetworkUrlValue        = true;
      this.socialNetworkUrlInputTarget.value = this.socialLinksObject.sanitize(socialNetworkIdValue, socialNetworkUrlValue);
      socialNetworkSlugSelector.value        = this.socialLinksObject.getProfileId(socialNetworkIdValue, socialNetworkUrlValue);
      this.socialNetworkUrlInputTarget.classList.remove("is-invalid");
      this.socialNetworkUrlInputTarget.classList.add("is-valid");

    } else {

      this.validSocialNetworkUrlValue = false;
      this.socialNetworkUrlInputTarget.classList.remove("is-valid");
    }
  }

  selectSocialNetworkFollowers(evt) {
    const socialNetworkFollowersLabelValue = this.socialNetworkFollowersSelectTarget.value;

    if (socialNetworkFollowersLabelValue != "") {

      this.validSocialNetworkFollowersValue = true;
      this.socialNetworkFollowersSelectTarget.classList.remove("is-invalid");
      this.socialNetworkFollowersSelectTarget.classList.add("is-valid");

    } else {

      this.validSocialNetworkFollowersValue = false;
      this.socialNetworkFollowersSelectTarget.classList.remove("is-valid");
    }
  }

  clickPrivacyAcceptanceCheckBox(evt) {
    const privacyAcceptanceLabelSelector = document.querySelector("div#user_metadata_privacy_acceptance");

    if (this.privacyAcceptanceCheckBoxTarget.checked) {

      this.validPrivacyAcceptanceCheckBoxValue = true;
      privacyAcceptanceLabelSelector.classList.remove("d-block");

    } else {

      this.validPrivacyAcceptanceCheckBoxValue = false;
    }
  }

  clickSubmitForm(evt) {
    evt.preventDefault();

    if (this.validateSubmitForm()) {
      this.backwardFormButtonTarget.disabled = true;
      this.submitFormButtonTarget.disabled   = true;
      this.loadingFormButtonTarget.classList.remove("d-none");
      this.loadingFormButtonTarget.classList.add("d-block");
      document.querySelector("#quiz_form").submit();
    }
  }

  clickSubmitFormWithoutValidation(evt) {
    evt.preventDefault();

    this.backwardFormButtonTarget.disabled = true;
    this.submitFormButtonTarget.disabled   = true;
    this.loadingFormButtonTarget.classList.remove("d-none");
    this.loadingFormButtonTarget.classList.add("d-block");
    document.querySelector("#quiz_form").submit();
  }

  validateSocialNetworkUrl() {
    const socialNetworkIdValue  = this.socialNetworkIdSelectTarget.value;
    const socialNetworkUrlValue = this.socialNetworkUrlInputTarget.value;

    return this.socialLinksObject.isValid(socialNetworkIdValue, socialNetworkUrlValue);
  }

  validateSubmitForm(evt) {
    const phoneLabelSelector             = document.querySelector("div#user_metadata_phone");
    const privacyAcceptanceLabelSelector = document.querySelector("div#user_metadata_privacy_acceptance");

    this.formElementValues = [
      this.validFirstNameValue,
      this.validLastNameValue,
      this.validEmailValue,
      this.validPhoneValue,
      this.validProfessionSlugValue,
      this.validSocialNetworkIdValue,
      this.validPrivacyAcceptanceCheckBoxValue
    ]

    if (this.requiredSocialNetworkDataValue) {
      this.formElementValues.push(this.validSocialNetworkUrlValue);
      this.formElementValues.push(this.validSocialNetworkFollowersValue);
    }

    this.formElementValuesValidation = this.formElementValues.every(Boolean);

    !this.validFirstNameValue                 && this.firstNameInputTarget.classList.add("is-invalid");
    !this.validLastNameValue                  && this.lastNameInputTarget.classList.add("is-invalid");
    !this.validEmailValue                     && this.emailInputTarget.classList.add("is-invalid");
    !this.validPhoneValue                     && this.phoneInputTarget.classList.add("is-invalid");
    !this.validPhoneValue                     && phoneLabelSelector.classList.add("d-block");
    !this.validProfessionSlugValue            && this.professionSlugSelectTarget.classList.add("is-invalid");
    !this.validSocialNetworkIdValue           && this.socialNetworkIdSelectTarget.classList.add("is-invalid");
    !this.validPrivacyAcceptanceCheckBoxValue && privacyAcceptanceLabelSelector.classList.add("d-block");

    if (this.requiredSocialNetworkDataValue) {
      !this.validSocialNetworkUrlValue          && this.socialNetworkUrlInputTarget.classList.add("is-invalid");
      !this.validSocialNetworkFollowersValue    && this.socialNetworkFollowersSelectTarget.classList.add("is-invalid");
    }

    // Allow Submit Button
    if (this.formElementValuesValidation === false) {
      this.loadingFormButtonTarget.classList.remove("d-block");
      this.loadingFormButtonTarget.classList.add("d-none");
      this.backwardFormButtonTarget.disabled = false;
      this.submitFormButtonTarget.disabled   = false;
    }

    // Debug Values
    // this.debugFormValues();

    return this.formElementValuesValidation;
  }

  debugFormValues(evt) {
    console.log(`Firstname: ${this.validFirstNameValue}`);
    console.log(`Lastname: ${this.validLastNameValue}`);
    console.log(`Email: ${this.validEmailValue}`);
    console.log(`Phone Number: ${this.validPhoneValue}`);
    console.log(`Profession Slug: ${this.validProfessionSlugValue}`);
    console.log(`Social Network Id: ${this.validSocialNetworkIdValue}`);
    console.log(`Social Network Url: ${this.validSocialNetworkUrlValue}`);
    console.log(`Social Network Followers: ${this.validSocialNetworkFollowersValue}`);
    console.log(`Required Social Network Data: ${this.requiredSocialNetworkDataValue}`);
    console.log(`Compliance Policy: ${this.validPrivacyAcceptanceCheckBoxValue}`);
    console.log(`Form Element Values: ${this.formElementValues}`);
    console.log(`Form Element Values Validation: ${this.formElementValuesValidation}`);
  }
}